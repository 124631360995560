/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.delete-modal {
  --backdrop-opacity: 1;
  backdrop-filter: blur(3px);

  &::part(backdrop) {
      background: #000;
  }

  &::part(handle) {
      background: var(--ion-color-primary);
      width: 150px;
      height: 6px;
  }

  &::part(content) {
      border-radius: 0px;
      box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
  }
}

// @font-face {
//   font-family: 'PlayfairDisplay-Regular';
//   src: url('./assets/fonts/PlayfairDisplay-Regular.ttf') format('ttf');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'ChampagneLimousinesBold';
//   src: url('./assets/fonts/ChampagneLimousinesBold.ttf') format('ttf');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: 'KendalType';
  src: url('./assets/fonts/FONTS/ITCAvantGardePro-Book.otf');

}

@font-face {
  font-family: "KendalTypeBoldB";
  src: url("./assets/fonts/FONTS/ITCAvantGardePro-BoldOblique.otf");
}

@font-face {
  font-family: "KendalTypeBold";
  src: url("./assets/fonts/FONTS/ITCAvantGardePro-Bold.otf");
}

@font-face {
  font-family: "KendalTypeOblique";
  src: url("./assets/fonts/FONTS/ITCAvantGardePro-ExtralightOblique.otf");
}

@font-face {
  font-family: "KendalType";
  src: url("./assets/fonts/FONTS/ITCAvantGardePro-Book.otf");
}

// @font-face {
//   font-family: "PlayfairDisplayB";
//   src: url("./assets/fonts/PlayfairDisplay-Black.ttf");
// }

$firstLetterColor: #FF4500;
$firstLetterColor2: #0081af;
$datetime-ios-placeholder-color:  #32db64;
$datetime-md-placeholder-color : #32db64;
$datetime-placeholder-color : #32db64;
$font-family-md-base: "KendalType";
$font-family-ios-base: "KendalType";
$font-family-wp-base: "KendalType";

.wider-popover .select-interface-option {

  width: 100%;
  --color-hover: #79193b;
  color: grey;
  white-space: normal !important;
  border-bottom: solid 2px #e9ecef;
  border-bottom-width: 1px;
  .item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h{
    white-space: normal !important;
}



//or just 

.sc-ion-label-md-h{
    white-space: normal !important;
}
}
.wider-popover .select-interface-option .item-radio-checked {
  color: #79193b;
  background-color: red !important;
}

.wider-popover .item-radio-checked.sc-ion-select-popover-md {
  --background: #f8f9fa;
    --background-focused: #dee2e6 !important;
}
.calendarColor{
  color:#0081af !important
}
.today{
  p{
    color: black !important; 
  }
  background-color: #ebebeb !important;
    color: black !important;
    border-radius: 36px !important;
}

.marked{
  p{
    color: white !important; 
  }
  background-color: #0081af !important;
  height:34px !important;
    width: 23px !important;
}

.on-selected{
  p{
    color: white !important; 
  }
  background-color: #ffa00b !important;
  
}

ion-header {
  ion-title {
    padding-inline-start: 0 !important;
    padding-inline-end: 0 !important;

  }

  ion-title.cal {
    inline-size: 150px;
    overflow-wrap: break-word;
  }
}

ion-calendar-modal{
  ion-header {
    ion-toolbar{
      ion-title {
        text-align:center
      }
    }

}
}
calendar{
  --background-color: red !important;
  color:red;
  ion-title{
    text-align: center;
  }
}

h2.title {
  font-size: 15px;
  margin: inherit !important;
  font-family: 'KendalType' !important;
font-weight:bold;
}

item.sc-ion-label-md-h, .item .sc-ion-label-md-h{
  white-space: normal !important;
}

//or just 

.sc-ion-label-md-h{
  white-space: normal !important;
}

.alert-radio-label.sc-ion-alert-md {
  white-space: pre-line !important;
}

.alert-radio-label.sc-ion-alert-ios {
   white-space: pre-line !important;
}
.swiper-pagination-bullet-active {
  background: white !important;
}
.swiper-pagination-bullet {
  background: white !important ;
}
ion-header{
  ion-button  {

    ion-icon,ion-back-button {
      font-size:25px !important;
    }
     // margin-top: 79px; // Margin top from the StatusBar
     // margin-right: 22px; // Maybe you don't need this, depending how your layout looks like
  }
 
  // Here is how you can center your title

}


#video {
  margin:auto;
  display:block;

  iframe {
    width: 100% !important;
  }
}